import { Component, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { ProjectContext, customTableStyles } from "../utils/constants";
import {
  Chart,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  BarController,
  BarElement,
  DoughnutController,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  PolarAreaController,
  PieController,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  scales,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  ArcElement,
  BarController,
  BarElement,
  DoughnutController,
  LineController,
  LineElement,
  PieController,
  PointElement,
  PolarAreaController,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  scales
);

const orderNosObj = {
  application_received: 0,
  under_process: 0,
  under_installation: 0,
  ic_completed: 0,
  invoice_submitted: 0,
  payment_received: 0,
};

const ageingOrderObj = {
  oc_pending_gt60: 0,
  ic_pending_gt60: 0,
  oc_pending_gt30: 0,
  ic_pending_gt30: 0,
  oc_pending_lt15: 0,
  ic_pending_lt15: 0,
  oc_overdue_lt15: 0,
  ic_overdue_lt15: 0,
  oc_overdue_gt30: 0,
  ic_overdue_gt30: 0,
};

class Dashboard extends Component {
  static contextType = ProjectContext;
  constructor(props) {
    super(props);
    this.state = {
      orderGroups: orderNosObj,
      projects: [],
      isLoading: true,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  async getProjects() {
    try {
      const response = await fetch("/api/projects");
      const json = await response.json();
      this.setState({ projects: json.length ? json : [] });
    } catch (e) {
      toast.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleFilterChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { activeProjectId, monthInterval, setCurrentProject } = this.context;
    if (name === "activeProjectId") {
      setCurrentProject({
        monthInterval: monthInterval,
        [name]: value ? value : "",
      });
    } else if (name === "monthInterval") {
      setCurrentProject({
        activeProjectId: activeProjectId,
        [name]: value ? value : "",
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  componentDidMount() {
    this.getProjects();
  }

  render() {
    const isLoggedIn = sessionStorage.getItem("userToken");
    const OG = this.state.orderGroups;
    if (isLoggedIn) {
      return (
        <ProjectContext.Consumer>
          {({ activeProjectId, monthInterval }) => (
            <Container fluid className="h-100 overflow-y-scroll">
              {!this.state.isLoading && (
                <div className="bg-light">
                  <nav className="p-2">
                    <div className="d-flex d-none d-sm-block text-color-b justify-items-center border py-1 px-3 align-items-center gap-3">
                      <div className="fs-6 fw-semibold text-uppercase text-center">
                        PREMIUM SOLAR's Order Management System.
                      </div>
                    </div>
                  </nav>
                  <nav className="px-2 pb-2">
                    <div className="d-flex text-color-b justify-items-start border py-1 px-3 align-items-center gap-3">
                      <div className="fs-6 d-none d-sm-block fw-semibold text-uppercase">
                        DAshboard
                      </div>
                      <div className="d-sm-none fw-semibold text-uppercase">
                        DAshboard
                      </div>
                    </div>
                  </nav>
                  {/* Filters */}
                  <section className="px-2">
                    <Row xs={12} className="m-0 p-0 gap-2 ">
                      <div className="p-2 border col col-md-4 d-grid gap-1 align-items-center">
                        <label
                          htmlFor="selectOption"
                          className="text-capitalize text-color-b"
                        >
                          Project Filter
                        </label>
                        <Form.Select
                          name="activeProjectId"
                          className="shadow-none rounded-0"
                          value={activeProjectId}
                          onChange={this.handleFilterChange}
                        >
                          <option value="">-- Select Project --</option>
                          {this.state.projects.map((p, i) => (
                            <option key={p.project_code} value={p.id}>
                              {p.project_name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Row>
                  </section>
                  {activeProjectId ? (
                    <>
                    <Row xs={12} className="my-2">
                        <Col xs={12} md={6}>
                          {/* Project Progress badges (Overall)*/}
                          <ProgressBadges projectId={activeProjectId} />                          
                        </Col>
                        <Col xs={12} md={6}>
                          {/* Project Progress badges (Daily)*/}
                          <DailyProgressBadges projectId={activeProjectId} />
                        </Col>
                      </Row>                      
                      {/* Ageing Summary */}
                      <Row xs={12}>
                        <Col xs={12} md={8}>
                          <AgeingBucket projectId={activeProjectId} />
                        </Col>
                      </Row>
                      {/* Line Chart
                      <section className="p-2">
                        <div className="border p-2 min-max-h">
                          <LineChart />
                        </div>
                      </section>
                    */}
                      {/*Order Progress Chart */}
                      <section className="p-2">
                        <Row className="m-0 px-0 pb-2">
                          <div className="p-2 border col col-md-4 d-grid gap-1 align-items-center">
                            <label
                              htmlFor="monthInterval"
                              className="text-capitalize text-color-b"
                            >
                              Select Time Intreval
                            </label>
                            <Form.Select
                              name="monthInterval"
                              className="shadow-none rounded-0"
                              value={monthInterval}
                              onChange={this.handleFilterChange}
                            >
                              <option value={0}> Current Month</option>
                              <option value={-1}> 2 Months</option>
                              <option value={-2}> 3 Months</option>
                              <option value={-5}> 6 Months</option>
                            </Form.Select>
                          </div>
                        </Row>
                        <div className="order-progress-chart row m-0 gap-2">
                          <div className="border col p-2 ">
                            <MonthlyOrderProgress
                              projectId={activeProjectId}
                              monthInterval={monthInterval}
                            />
                          </div>
                        </div>
                      </section>
                    </>
                  ) : (
                    <h5 className="text-center fw-semibold text-secondary py-3">
                      Select a project to continue..
                    </h5>
                  )}
                </div>
              )}
            </Container>
          )}
        </ProjectContext.Consumer>
      );
    } else {
      return <Navigate to="login" />;
    }
  }
}

const LineChart = () => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "SOH value",
      },
      tooltip: {
        usePointStyle: true,
      },
    },
    animations: {
      tension: {
        duration: 1000,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
  };
  const labels = ["January", "February", "March", "April", "May", "June"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "First dataset",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: [0, 10, 5, 2, 20, 30, 45],
      },
      {
        label: "Second dataset",
        backgroundColor: "#376ba6",
        borderColor: "#376ba6",
        data: [5, 15, 20, 18, 25, 25],
      },
    ],
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      setChartData(data);
    }
  }, []);

  return (
    <>
      {/* <Line data={data} /> */}
      <Chart
        ref={chartRef}
        type="line"
        data={chartData}
        options={options}
        onClick={(event) => {
          const dataset = getDatasetAtEvent(chartRef.current, event);
          const element = getElementAtEvent(chartRef.current, event);
          const elements = getElementsAtEvent(chartRef.current, event);
          console.log("dataset", dataset);
          console.log("element", element);
          console.log("elements", elements);
        }}
      />
    </>
  );
};

const BarChart = ({ labels, datasets, titleText, chartAxis }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const fontStyle = {
    size: 16,
    weight: "bold",
    family: "'Nunito', sans-serif",
  };
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: chartAxis,
    scales: {
      x: {
        ticks: {
          font: fontStyle,
        },
      },
      y: {
        ticks: {
          font: fontStyle,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          font: fontStyle,
        },
      },
      title: {
        display: true,
        text: titleText,
        color: "#0c0765",
        font: fontStyle,
      },
    },
  };
  const data = {
    labels: labels,
    datasets: datasets,
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      setChartData(data);
    }
  }, [labels, datasets, titleText]);

  return (
    <>
      <Chart type="bar" ref={chartRef} data={chartData} options={options} />
    </>
  );
};

const ProgressBadges = (props) => {
  const [orderGroups, setOrderGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);

  async function getProjectOrderStatus(projectId) {
    let OG = orderNosObj;
    console.log(`/api/project-orders-status/${projectId}`);
    try {
      const response = await fetch(`/api/project-orders-status/${projectId}`);
      const json = await response.json();
      console.log("resultJson: ", json);
      OG = json.length ? json[0] : orderNosObj;
    } catch (e) {
      toast.error(e);
    } finally {
      setOrderGroups([
        {
          label: "Total Orders Received",
          value: OG.total_orders ? OG.total_orders : 0,
          bgColor: "primary",
        },
        {
          label: "Under Process",
          value: OG.under_process ? OG.under_process : 0,
          bgColor: "primary",
        },
        {
          label: "Under Installation",
          value: OG.under_installation ? OG.under_installation : 0,
          bgColor: "primary",
        },
        {
          label: "I&C Completed",
          value: OG.ic_completed ? OG.ic_completed : 0,
          bgColor: "primary",
        },
        {
          label: "Invoice Submitted",
          value: OG.invoice_submitted ? OG.invoice_submitted : 0,
          bgColor: "primary",
        },
        {
          label: "Payment Received",
          value: OG.payment_received ? OG.payment_received : 0,
          bgColor: "primary",
        },
      ]);
      console.log("OG: ", orderGroups);
      setLoading(false);
    }
  }

  useEffect(() => {
    getProjectOrderStatus(props.projectId);
  }, [props]);

  return (
    <section className="p-2">
      <h6 className="text-center mb-3 text-color-b text-uppercase">Overall Order Progress</h6>
      {!isLoading && (
        <div className="row m-0 row-cols-1 row-cols-sm-3 row-cols-md-3 m-0 p-0 gap-2 justify-content-center">
          {orderGroups.map((group, index) => (
            <div key={group.label + index} className="border col px-0 ">
              <div className="text-uppercase text-light bg-bluEs text-center">
                {group.label}
              </div>
              <div className="fs-4 text-center text-primary fw-bold">
                {group.value}
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

const DailyProgressBadges = (props) => {
  const [orderGroups, setOrderGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);

  async function getDailyProjectOrderProgress(projectId) {
    let OG = orderNosObj;
    try {
      const response = await fetch(
        `/api/daily-project-order-progress/${projectId}`
      );
      const json = await response.json();
      console.log("resultJson: ", json);
      OG = json.length ? json[0] : orderNosObj;
    } catch (e) {
      toast.error(e);
    } finally {
      setOrderGroups([
        {
          label: "Work Orders Received",
          value: OG.work_orders_received ? OG.work_orders_received : 0,
          bgColor: "primary",
        },
        {
          label: "Site Survey Completed",
          value: OG.site_survey_completed ? OG.site_survey_completed : 0,
          bgColor: "primary",
        },
        {
          label: "Materials Dispatched",
          value: OG.materials_dispatched ? OG.materials_dispatched : 0,
          bgColor: "primary",
        },
        {
          label: "Installation Completed",
          value: OG.installation_completed ? OG.installation_completed : 0,
          bgColor: "primary",
        },
        {
          label: "Inspection Completed",
          value: OG.inspection_completed ? OG.inspection_completed : 0,
          bgColor: "primary",
        },
        {
          label: "Invoice Submitted",
          value: OG.invoice_submitted ? OG.invoice_submitted : 0,
          bgColor: "primary",
        },
      ]);
      console.log("OG: ", orderGroups);
      setLoading(false);
    }
  }

  useEffect(() => {
    getDailyProjectOrderProgress(props.projectId);
  }, [props]);

  return (
    <section className="p-2">
      <h6 className="text-center mb-3 text-color-b text-uppercase">Today Order Progress</h6>
      {!isLoading && (
        <div className="row m-0 row-cols-1 row-cols-sm-3 row-cols-md-3 m-0 p-0 gap-2 justify-content-center">
          {orderGroups.map((group, index) => (
            <div key={group.label + index} className="border col px-0 ">
              <div className="text-uppercase text-light bg-bluEs text-center">
                {group.label}
              </div>
              <div className="fs-4 text-center text-primary fw-bold">
                {group.value}
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

const AgeingBucket = (props) => {
  const navigate = useNavigate();
  const [orderAges, setOrderAges] = useState(ageingOrderObj);

  const columns = [
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "Installations Pending",
      selector: (row) => row.installations_pending,
      sortable: true,
    },
    {
      name: "Order Completions Pending",
      selector: (row) => row.order_completions_pending,
      sortable: true,
    },
  ];

  const tableData = [
    {
      id: "cp_gt_60",
      age: "> 60 days",
      installations_pending: orderAges.ic_pending_gt60,
      order_completions_pending: orderAges.oc_pending_gt60,
    },
    {
      id: "cp_gt_30",
      age: "> 30 days",
      installations_pending: orderAges.ic_pending_gt30,
      order_completions_pending: orderAges.oc_pending_gt30,
    },
    {
      id: "cp_lt_15",
      age: "< 15 days",
      installations_pending: orderAges.ic_pending_lt15,
      order_completions_pending: orderAges.oc_pending_lt15,
    },
    {
      id: "ov_lt_15",
      age: "Overdue < 15 days",
      installations_pending: orderAges.ic_overdue_lt15,
      order_completions_pending: orderAges.oc_overdue_lt15,
    },
    {
      id: "ov_gt_30",
      age: "Overdue > 30 days",
      installations_pending: orderAges.ic_overdue_gt30,
      order_completions_pending: orderAges.oc_overdue_gt30,
    },
  ];

  const THeader = () => {
    return (
      <>
        <h5 className="mb-0 text-color-b">Orders Ageing</h5>
      </>
    );
  };

  const getOrdersAgeingSummary = async (projectId) => {
    try {
      const response = await fetch(`/api/orders-ageing-summary/${projectId}`);
      const json = await response.json();
      setOrderAges(json.length ? json[0] : ageingOrderObj);
    } catch (e) {
      toast.error(e);
    }
  };

  const view = (row, event) => {
    if (props.projectId && row.id) {
      navigate(`/age-group-orders/${props.projectId}/${row.id}`);
    }
  };

  useEffect(() => {
    if (props.projectId) {
      getOrdersAgeingSummary(props.projectId);
    } else {
      setOrderAges(ageingOrderObj);
    }
  }, [props]);

  return (
    <>
      <div id="order-list" className="mb-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}
            columns={columns}
            data={tableData}
            customStyles={customTableStyles}
            fixedHeader
            fixedHeaderScrollHeight="440px" //remove this if you dont need table scroll
            selectableRowsHighlight
            pointerOnHover
            className=" border z-10"
            onRowClicked={view}
          />
        </div>
      </div>
    </>
  );
};

const MonthlyOrderProgress = (props) => {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  function generateChartData(monthlyOrders) {
    const labels = monthlyOrders.map((row) => row.month_name);
    const datasets = [
      {
        label: "Site Surveys",
        backgroundColor: "lightblue",
        borderColor: "lightblue",
        data: monthlyOrders.map((row) => row.sitesurvey_completed_count),
      },
      {
        label: "Invoice Generated",
        backgroundColor: "rgba(44, 129, 160, 0.62)",
        borderColor: "rgba(44, 129, 160, 1)",
        data: monthlyOrders.map((row) => row.invoice_generated_count),
      },
      {
        label: "Materials Dispatched",
        backgroundColor: "rgba(60, 142, 208, 0.8)",
        borderColor: "rgba(60, 142, 208, 1)",
        data: monthlyOrders.map((row) => row.materials_dispatched_count),
      },
      {
        label: "Installations",
        backgroundColor: "royalblue",
        borderColor: "royalblue",
        data: monthlyOrders.map((row) => row.installation_completed_count),
      },
      {
        label: "Report & Invoice Submitted",
        backgroundColor: "blue",
        borderColor: "blue",
        data: monthlyOrders.map((row) => row.report_invoice_completed_count),
      },
    ];
    setLabels(labels);
    setDatasets(datasets);
  }

  const getMonthlyOrderProgress = async (projectId, monthInterval) => {
    try {
      const response = await fetch(
        `/api/orders-monthly-progress?projectId=${projectId}&monthInterval=${monthInterval}`
      );
      const json = await response.json();
      generateChartData(json.length ? json : []);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    if (props.projectId) {
      getMonthlyOrderProgress(props.projectId, props.monthInterval);
    }
  }, [props]);

  return (
    <BarChart
      labels={labels}
      datasets={datasets}
      titleText="ORDER COMPLETION PROGRESS - STAGE WISE (MONTHLY)"
      chartAxis="y"
    />
  );
};

export { Dashboard };
